import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import '@fortawesome/fontawesome-svg-core/styles.css';

// Import only the icons you actually use
import {
  faInstagram,
  faGithub,
  faLinkedin,
  faUnity,
} from '@fortawesome/free-brands-svg-icons';

import {
  faArrowDown,
  faUsers,
  faToolbox,
  faClock,
  faUser,
  faCodeBranch,
  faXmark,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';

import {
  faSquarePlus,
  faSquareMinus,
} from '@fortawesome/free-regular-svg-icons';

// Add icons to FontAwesome's library
library.add(
  faInstagram,
  faGithub,
  faLinkedin,
  faArrowDown,
  faUsers,
  faToolbox,
  faClock,
  faUser,
  faCodeBranch,
  faXmark,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faSquarePlus,
  faSquareMinus,
  faUnity,
  faScrewdriverWrench,
);

export { FontAwesomeIcon };
